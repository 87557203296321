// import {Data} from '@/model'
import Color from './color'
import Echarts from 'echarts'

class System {
  static Pie1 (data) {
    if (!data) return
    let options = {
      title: {
        text: '总人数',
        subtext: '413216546',
        left: '38%',
        top: '38%',
        textAlign: 'center',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14,
          align: 'center'
        }
      },
      color: ['#0091FF', '#BFBFBF'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      grid: {
        top: '0%'
      },
      series: [
        {
          name: '人数',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['55%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      sum += data[key]
      options.series[0]['data'].push({
        value: data[key],
        name: key
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Pie2 (data) {
    if (!data) return
    let options = {
      title: {
        text: '即将停用',
        subtext: '413216546',
        left: '38%',
        top: '38%',
        textAlign: 'center',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14,
          align: 'center'
        }
      },
      color: ['#9FC676', '#F7B500', '#EE698E'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      grid: {
        top: '0%'
      },
      series: [
        {
          name: '账号数',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['55%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = data['即将停用']
    // let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      // sum += data[key]
      options.series[0]['data'].push({
        value: data[key],
        name: key
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Pie3 (data) {
    if (!data) return
    let options = {
      title: {
        text: '锁定账号',
        subtext: '413216546',
        left: '38%',
        top: '38%',
        textAlign: 'center',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14,
          align: 'center'
        }
      },
      color: ['#69D1EE', '#EE8769', '#EEC969', '#8769CC'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      grid: {
        top: '0%'
      },
      series: [
        {
          name: '账号数',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['55%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      if (key !== '未锁定') {
        sum += data[key]
      }
      options.series[0]['data'].push({
        value: data[key],
        name: key
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Bar1 (data) {
    if (!data) return
    let options = {
      tooltip: {
        trigger: 'item'
      },
      color: ['#0091FF'],
      grid: {
        x: '50',
        y: '15%',
        x2: '0%',
        y2: '10%'
      },
      xAxis: {
        type: 'category',
        data: [],
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Direct',
        barMaxWidth: 10,
        type: 'bar',
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: []
      }]
    }

    for (let key in data) {
      options.xAxis.data.push(data[key]['x'])
      options.series[0].data.push(data[key]['y'])
    }

    return options
  }
  static Pie4 (data) {
    if (!data) return
    let options = {
      title: {
        text: '已采集',
        subtext: '413216546',
        left: '38%',
        top: '38%',
        textAlign: 'center',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14,
          align: 'center'
        }
      },
      color: ['#0091FF', '#BFBFBF'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      grid: {
        top: '0%'
      },
      series: [
        {
          name: '人数',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['55%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = data['已采集']
    for (let key in data) {
      // options.legend.data.push(key)
      options.series[0]['data'].push({
        value: data[key],
        name: key
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Pie5 (data) {
    if (!data) return
    let options = {
      title: {
        text: '验证次数',
        subtext: '413216546',
        left: '38%',
        top: '38%',
        textAlign: 'center',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14,
          align: 'center'
        }
      },
      // color: ['#9FC676', '#F7B500', '#EE698E'],
      color: ['#EEC969', '#EE8769'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      grid: {
        top: '0%'
      },
      series: [
        {
          name: '次数',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['55%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      sum += data[key]
      options.series[0]['data'].push({
        value: data[key],
        name: key
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Pie6 (data) {
    if (!data) return
    let options = {
      title: {
        text: '失败次数',
        subtext: '413216546',
        left: '38%',
        top: '38%',
        textAlign: 'center',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14,
          align: 'center'
        }
      },
      color: ['#69D1EE', '#EE8769', '#EEC969', '#8769CC'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      grid: {
        top: '0%'
      },
      series: [
        {
          name: '次数',
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['55%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    console.log(1111, data)
    let sum = data['失败']
    for (let key in data) {
      // options.legend.data.push(key)
      options.series[0]['data'].push({
        value: data[key],
        name: key
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Bar2 (data) {
    if (!data) return
    let options = {
      tooltip: {
        trigger: 'item'
      },
      color: ['#EEC969', '#EE8769'],
      grid: {
        x: '50',
        y: '15%',
        x2: '0%',
        y2: '10%'
      },
      legend: {
        // right: '0%',
        top: '5%',
        // orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      xAxis: {
        type: 'category',
        data: [],
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: '人脸比对',
        barMaxWidth: 10,
        type: 'bar',
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: []
      },{
        name: '公安认证',
        barMaxWidth: 10,
        type: 'bar',
        stack: 'total',
        emphasis: {
            focus: 'series'
        },
        data: []
      }]
    }

    for (let key in data) {
      options.xAxis.data.push(data[key]['x'])
      options.series[0].data.push(data[key]['y2'])
      options.series[1].data.push(data[key]['y1'])
    }

    return options
  }
  static Bar3 (data) {
    if (!data) return
    let options = {
      legend: {
        left: 'center',
        top: '5%',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      color: ['#EE698E', '#0091FF', '#8769CC'],
      tooltip: {
        trigger: 'item'
      },
      grid: {
        x: '50',
        y: '15%',
        x2: '0%',
        y2: '10%'
      },
      xAxis: {
        type: 'category',
        data: [],
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value'
      },
      series: [
      {
        name: '登录失败',
        stack: 'total',
        barMaxWidth: 20,
        type: 'bar',
        emphasis: {
            focus: 'series'
        },
        data: []
      },{
        name: '登录成功',
        stack: 'total',
        barMaxWidth: 20,
        type: 'bar',
        emphasis: {
            focus: 'series'
        },
        data: []
      }]
    }

    for (let key in data) {
      options.xAxis.data.push(data[key]['x'])
      options.series[1].data.push(data[key]['y1'])
      options.series[0].data.push(data[key]['y2'])
      // options.series[2].data.push(data[key]['y3'] || 3)
    }

    return options
  }
  static Bar4 (data) {
    if (!data) return
    let options = {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          x: '50',
          y: '10%',
          x2: '0%',
          y2: '30%'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: { interval: 0, rotate: 30 }
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '次数',
          barMaxWidth: 20,
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: []
        }]
    }

    for (let key in data) {
      options.xAxis.data.push(data[key]['x'])
      options.series[0].data.push(data[key]['y'])
    }

    return options
  }
  static Pie7 (data) {
    if (!data) return
    let options = {
      title: {
        text: '访问次数',
        subtext: '413216546',
        left: 'center',
        top: '38%',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14
        }
      },
      color: ['#75CBED', '#F8D1CE', '#E96D5D', '#FBECBD', '#F6C02D', '#D0D6E0', '#657898', '#D0F4E4', '#64DAAC', '#D0DFFD'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      series: [
        {
          name: '次数',
          type: 'pie',
          top: '0%',
          radius: ['58%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      sum += data[key]['accesscount']
      options.series[0]['data'].push({
        value: data[key]['accesscount'],
        name: data[key]['servicename']
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Pie8 (data) {
    if (!data) return
    let options = {
      title: {
        text: '调用次数',
        subtext: '413216546',
        left: 'center',
        top: '38%',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14
        }
      },
      color: ['#75CBED', '#F8D1CE', '#E96D5D', '#FBECBD', '#F6C02D', '#D0D6E0', '#657898', '#D0F4E4', '#64DAAC', '#D0DFFD'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      series: [
        {
          name: '次数',
          type: 'pie',
          top: '0%',
          radius: ['58%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      sum += data[key]['count']
      options.series[0]['data'].push({
        value: data[key]['count'],
        name: data[key]['apiname']
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
  static Pie9 (data) {
    if (!data) return
    let options = {
      title: {
        text: '调用次数',
        subtext: '413216546',
        left: 'center',
        top: '38%',
        textStyle: {
          color: 'rgba(0,0,0,0.60)',
          fontSize: 14
        },
        subtextStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 14
        }
      },
      color: ['#75CBED', '#F8D1CE', '#E96D5D', '#FBECBD', '#F6C02D', '#D0D6E0', '#657898', '#D0F4E4', '#64DAAC', '#D0DFFD'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        right: '0%',
        top: 'center',
        orient: 'vertical',
        itemWidth: 8,
        itemHeight: 8,
        icon: 'circle',
        textStyle: {
          color: 'rgba(0,0,0,0.85)',
          fontSize: 12
        }
      },
      series: [
        {
          name: '次数',
          type: 'pie',
          top: '0%',
          radius: ['58%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: []
        }
      ]
    }

    let sum = 0
    for (let key in data) {
      // options.legend.data.push(key)
      sum += data[key]['count']
      options.series[0]['data'].push({
        value: data[key]['count'],
        name: data[key]['name']
      })
    }
    options['title']['subtext'] = String(sum)

    return options
  }
}

export default {
  System
}
